
import { plainToClass } from 'class-transformer';
import { data } from 'v-calendar/dist/types/tests/unit/util/dayData';
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import CartInfo from '@/components/CartInfo.vue';
import CartItem from '@/components/CartItem.vue';
import OrderComplete from '@/components/OrderComplete.vue';
import OrderSteps from '@/components/OrderSteps.vue';
import moneyString from '@/helpers/moneyString';
import TabSwitcher from '@/shared/components/TabSwitcher.vue';
import TwoColPage from '@/shared/components/TwoColPage.vue';
import { CartItem as CartItemModel } from '@/shared/models/CartItem';
import { Order } from '@/shared/models/Order';

export default {
    components: {
        OrderComplete,
        OrderSteps,
        CartItem,
        TabSwitcher,
        TwoColPage,
        CartInfo,
    },
    setup() {
        const cart = ref(Array<CartItemModel>());
        const orderRef = ref(new Order());
        const accepted = ref(false);

        const tabs = ref([
            {
                id: 1,
                name: 'Winkelmand',
                active: true,
                disabled: computed(() => orderRef.value.no),
            },
            {
                id: 2,
                name: 'Bestellen',
                active: false,
                disabled: computed(() => cart.value.length === 0 || orderRef.value.no),
            },
            {
                id: 3,
                name: 'Bevestiging',
                active: false,
                disabled: computed(() => (!orderRef.value.no && !accepted.value) || !orderRef.value.no),
            },
        ]);

        const store = useStore();
        const money = moneyString();
        const currentTab = computed(() => tabs.value.find((tabItem) => tabItem.active));
        const total = ref(0);
        const shipping_costs = ref(0);
        const total_quantity = ref(0);
        const minPrice = ref(0);

        function getCart() {
            const dataLayer = window.dataLayer || [];
            store.dispatch('GET_CART').then((res) => {
                cart.value = plainToClass(CartItemModel, res.data.data as CartItemModel[]);
                total.value = res.data.total_amount_with_shipping_costs;
                total_quantity.value = res.data.total_quantity;
                shipping_costs.value = res.data.shipping_costs;
                minPrice.value = res.data.shipping_costs_min_amount;

                const structuredItems = cart.value.map((itemData, index) => {
                    const { item } = itemData;

                    return {
                        item_id: item.no,
                        item_name: item.name,
                        index,
                        item_brand: item.color,
                        item_category: item.country.name,
                        item_category2: item.region.name,
                        item_category3: item.estate.name,
                        item_category4: `${item.alcohol_percentage}%`,
                        item_category5: item.grapes.map((grape) => grape.name).join(', '),
                        price: itemData.price,
                        quantity: itemData.quantity,
                    };
                });

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'view_cart',
                    ecommerce: {
                        currency: 'EUR',
                        value: res.data.total_amount,
                        items: structuredItems,
                    },
                });
            });
        }

        function updateCart(cartData: any) {
            cart.value = plainToClass(CartItemModel, cartData.data as CartItemModel[]);
            total.value = cartData.total_amount_with_shipping_costs;
            total_quantity.value = cartData.total_quantity;
            shipping_costs.value = cartData.shipping_costs;
            minPrice.value = cartData.shipping_costs_min_amount;
        }

        function removeItem(item: any) {
            store.dispatch('CART_UPDATE', { item_no: item.item.id, quantity: 0, salesorder: item.salesorder })
                .then((res) => {
                    cart.value = cart.value.filter((cartItem: any) => cartItem !== item);
                    total.value = res.data.total_amount_with_shipping_costs;
                    total_quantity.value = res.data.total_quantity;
                    shipping_costs.value = res.data.shipping_costs;
                });
        }

        function isActiveTab(name: string) {
            return tabs.value.find((tab) => tab.name === name)?.active;
        }

        function setActiveTab(name: string) {
            const foundTab = tabs.value.find((tab) => tab.name === name);
            if (foundTab && foundTab.disabled) {
                return;
            }

            window.scrollTo(0, 0);

            tabs.value.map((tab: any) => {
                if (tab.name === name) {
                    tab.active = true;
                    return tab;
                }

                tab.active = false;
                return tab;
            });
        }

        const formData = ref({
            requested_delivery_date: '',
            address_code: '',
            shipping_code: '',
            remarks: '',
        });

        const serverValidation: any = ref('');

        function order() {
            if (!accepted.value) {
                const acceptElement = document.getElementById('step-3');
                if (acceptElement) {
                    acceptElement.scrollIntoView({ behavior: 'smooth' });
                }

                return;
            }

            store.dispatch('CART_SEND', formData.value).then((res: any) => {
                window.scrollTo(0, 0);
                orderRef.value = res.data.data;
                setActiveTab('Bevestiging');
            }).catch((err: any) => {
                if (err && err.response) {
                    serverValidation.value = 'Er ging iets mis bij het plaatsen van de bestelling.';
                }
            });
        }

        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;

        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
            getCart();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        function getTitle() {
            const activeTab = tabs.value.find((tab) => tab.active);
            if (activeTab) {
                if (width.value <= 767) {
                    if (activeTab.name !== 'Bestellen') {
                        return activeTab.name;
                    }

                    return '';
                }

                return activeTab.name;
            }

            return '';
        }

        return {
            cart,
            total_quantity,
            money,
            total,
            shipping_costs,
            minPrice,
            tabs,
            width,
            orderRef,
            formData,
            accepted,
            currentTab,
            serverValidation,
            isActiveTab,
            removeItem,
            setActiveTab,
            order,
            getTitle,
            updateCart,
        };
    },
};
